import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	OnChanges,
	SimpleChanges,
	Renderer2,
} from '@angular/core';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-custom-modal',
	templateUrl: './custom-modal.component.html',
	styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit, OnDestroy, OnChanges {

	@Input() title: string;
	@Input() show: boolean;
	@Input() modalColor: string = '#fff';
	@Input() arrowColor: string = '#fff';
	@Input() modalWidth: {width: string, maxWidth: string, minWidth: string} = {width: 'auto', maxWidth: 'auto', minWidth: 'auto'};
	@Input() modalHeight: string = 'auto';
	@Input() position: { top: number, left: number };
	@Input() closeIcon: string;
	@Input() customModalType: string = 'custom-modal';

	public _myIcons = Icons;
	public _icons = Icons.icon;
	@Output() showChange = new EventEmitter<boolean>();

	@ViewChild('modalComponent', {static: false}) modalComponent: ElementRef;

	constructor(
		private el: ElementRef, private renderer: Renderer2
	) {

	}

	ngOnInit() {
		this.show = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.arrowColor && this.arrowColor) {
			this.renderer.setStyle(this.el.nativeElement.querySelector('.custom-modal'), '--arrow-color', this.arrowColor);
		}
		if (changes.show && this.show) {
			setTimeout(() => {
				this.position.top = this.position.top - (this.modalComponent.nativeElement.offsetHeight - 35);
			}, 10)
		}
	}

	ngOnDestroy() {
		document.removeEventListener('click', this.onDocumentClick.bind(this));
	}

	onDocumentClick(event: MouseEvent) {
		if (this.show && this.modalComponent && !this.modalComponent.nativeElement.contains(event.target)) {
			this.closeModal();
		}
	}

	closeModal() {
		this.show = false;
		this.showChange.emit(this.show);
	}

}